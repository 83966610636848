<template>
  <div class="">
    <div class="view" v-if="!isMobile">
      <div class="title">
        <img src="../assets/logo2.png" alt="" />
        <span>{{ $t('message.ContactEmail') }}：whatsbuy123@gmail.com</span>
        <div class="language-switch">
          <img v-if="lang === 'zh'" src="@/assets/english.png" @click="changeLanguage('en')" alt="Switch to English">
          <img v-else src="@/assets/chinese.png" @click="changeLanguage('zh')" alt="切换到中文">
        </div>

      </div>
      <div class="block">
        <div class="text_box">
          <div>
            <span><a href="https://selleradmin.whatsbuy.com/">{{ $t('message.openStore') }}</a></span>
            <span><a href="https://supplieradmin.whatsbuy.com/" class="gys"
                >{{ $t('message.applySupplier') }}</a
              ></span
            >
          </div>
        </div>

        <el-carousel height="500px">
          <el-carousel-item v-for="(item, index) in img_arr" :key="index">
            <div class="item">
              <img :src="item.current" />
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>

      <div class="content" :style="{ backgroundImage: `url(${a1})` }">
        <ul class="flex-container">
          <li v-for="(image, index) in currentImages" :key="index">
            <div class="image-wrapper">
              <img :src="image" alt="" />
            </div>
          </li>
        </ul>
      </div>

      <div class="info">
        <img :src="a2" alt="" />
      </div>
      <div class="info">
        <img :src="a3" alt="" />
      </div>
      <div class="a4" :style="{ backgroundImage: `url(${a4})` }">
        <ul>
          <li v-for="(image, index) in a4_imgs" :key="index">
            <img :src="image" alt="" />
          </li>

        </ul>
      </div>

      <!-- 合作 -->
      <div class="cooperation">
        <h5>{{ lang == 'zh' ? ' 海外渠道合作，快速开启数字出海之旅':'Cooperate with overseas channels to quickly start your digital overseas journey'}}</h5>
        <ul>
          <li v-for="(image, index) in images" :key="index">
            <img
              :src="image.current"
              @mouseover="mouseOver(image)"
              @mouseout="mouseOut(image)"
              alt=""
            />
          </li>
        </ul>
        <div>{{ $t('message.joinNow') }}</div>
      </div>

      <div class="bottom">
        <ul>
          <li class="logo_box">
            <img src="@/assets/logo.png" alt="" />
          </li>
          <li class="text_box">
            <div class="">{{ $t('message.ContactEmail') }}</div>
            <div class="phone">whatsbuy123@gmail.com</div>
            <div>{{ $t('message.Address') }}</div>
            <div>{{ $t('message.Administrative') }}</div>
            <div>{{ $t('message.ZipCode') }}</div>
          </li>
          <li class="img_box">
            <img src="@/assets/whatsbuy_code.png" alt="" />
            <div>{{ $t('message.WeChat') }}</div>
          </li>
          <li class="img_box">
            <img src="@/assets/store.png" alt="" />
            <div>{{ $t('message.QR') }}</div>
          </li>
        </ul>
        <p>
          {{ $t('message.Haodi') }}
          <a href="https://beian.miit.gov.cn" style="color: #bebebe">{{ $t('message.ICP') }}</a>
        </p>
      </div>
    </div>

    <div class="phone view" v-if="isMobile">
      <div class="title">
        <span>{{ $t('message.ContactEmail') }}：whatsbuy123@gmail.com</span>
        <div class="language-switch">
          <img v-if="lang === 'zh'" src="@/assets/english.png" @click="changeLanguage('en')" alt="Switch to English">
          <img v-else src="@/assets/chinese.png" @click="changeLanguage('zh')" alt="切换到中文">
        </div>

      </div>
      <!-- 轮播图 -->
      <div class="block">
        <div class="text_box">
          <div>
            <span><a href="https://selleradmin.whatsbuy.com/">{{ $t('message.openStore') }}</a></span>
            <span><a href="https://supplieradmin.whatsbuy.com/" class="gys">{{ $t('message.applySupplier') }}</a></span>
          </div>
        </div>

        <el-carousel height="300px">
          <el-carousel-item v-for="(item, index) in img_arr" :key="index">
            <div class="item">
              <img :src="item.current" />
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <!--  -->
      <div class="content" :style="{ backgroundImage: `url(${a1_phone})` }">
        <ul class="flex-container">
          <li v-for="(image, index) in currentImages" :key="index">
            <div class="image-wrapper">
              <img :src="image" alt="" />
            </div>
          </li>
        </ul>
      </div>
      <div class="image-wrapper">
          <img :src="lang == 'zh' ? require('@/assets/a3_phone.png') : require('@/assets/a3_phone_en.png')" alt="">
      </div>
      <div class="image-wrapper">
          <img :src="lang == 'zh' ? require('@/assets/a4_phone.png') : require('@/assets/a4_phone_en.png')" alt="">
      </div>
      <div class="a7" style="font-size:0">
          <img :src="lang == 'zh' ? require('@/assets/a7_phone.png') : require('@/assets/a7_phone_en.png')" alt="">
      </div>
      <div class="a5">
        <h5>{{ lang == 'zh' ? '海外渠道合作，快速开启数字出海之旅':'Cooperate with overseas channels to quickly start your digital overseas journey'}}</h5>
        <ul>
          <li>
            <img :src="lang == 'zh' ? require('@/assets/a65.png') : require('@/assets/a65_en.png')" alt="">
          </li>
          <li>
            <img :src="lang == 'zh' ? require('@/assets/a66.png') : require('@/assets/a66_en.png')" alt="">
          </li>
          <li>
            <img :src="lang == 'zh' ? require('@/assets/a67.png') : require('@/assets/a67_en.png')" alt="">
          </li>
          <li>
            <img :src="lang == 'zh' ? require('@/assets/a68.png') : require('@/assets/a68_en.png')" alt="">
          </li>
        </ul>
        <div class="btn">{{ $t('message.joinNow') }}</div>

      </div>
      <div class="a6">
        <img src="@/assets/logo2.png" alt="" class="logo">
        <img src="@/assets/whatsbuy_code.png" alt="" class="code">
        <div class="text">{{ $t('message.WeChat') }}</div>
        <img src="@/assets/store.png" alt="" class="code">
        <div class="text" style="margin-bottom: 40px">{{ $t('message.QR') }}</div>

        <div class="info">
          <div>{{ $t('message.ContactEmail') }}</div>
          <div class="phone_number">whatsbuy123@gmail.com</div>
          <div>{{ $t('message.Address') }}</div>
          <div>{{ $t('message.ZipCode') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Tab from "../components/my_tab.vue";

export default {
  data() {
    return {
      lang:'zh',
    img_arr: [
      {
        img: {
          en: require("../assets/b1_en.png"),
          zh: require("../assets/b1.png"),
        },
        current: '', // will be updated in created() method
      },
      {
        img: {
          en: require("../assets/b2_en.png"),
          zh: require("../assets/b2.png"),
        },
        current: '', // will be updated in created() method
      },
      {
        img: {
          en: require("../assets/b3_en.png"),
          zh: require("../assets/b3.png"),
        },
        current: '', // will be updated in created() method
      },
    ],
      images: [
        {
          src: {
            en: require("@/assets/a61_en.png"),
            zh: require("@/assets/a61.png"),
          },
          hover: {
            en: require("@/assets/a65_en.png"),
            zh: require("@/assets/a65.png"),
          },
          current: '', // will be updated in created() method
        },
        {
          src: {
            en: require("@/assets/a62_en.png"),
            zh: require("@/assets/a62.png"),
          },
          hover: {
            en: require("@/assets/a66_en.png"),
            zh: require("@/assets/a66.png"),
          },
          current: '', // will be updated in created() method
        },
        {
          src: {
            en: require("@/assets/a63_en.png"),
            zh: require("@/assets/a63.png"),
          },
          hover: {
            en: require("@/assets/a67_en.png"),
            zh: require("@/assets/a67.png"),
          },
          current: '', // will be updated in created() method
        },
        {
          src: {
            en: require("@/assets/a64_en.png"),
            zh: require("@/assets/a64.png"),
          },
          hover: {
            en: require("@/assets/a68_en.png"),
            zh: require("@/assets/a68.png"),
          },
          current: '', // will be updated in created() method
        },
      ],



      windowWidth: 0,
      a1:require("../assets/a1.png"),
      a1_phone:require("../assets/a1_phone.png"),
      a2:require("../assets/a2.png"),
      a3:require("../assets/a3.png"),
      a4:require("../assets/a4.png"),
      a4_arr:{
        en:{
          a41:require("../assets/a41_en.png"),
          a42:require("../assets/a42_en.png"),
          a43:require("../assets/a43_en.png"),
          a44:require("../assets/a44_en.png"),
          a45:require("../assets/a45_en.png"),
          a46:require("../assets/a46_en.png"),
          a47:require("../assets/a47_en.png"),
          a48:require("../assets/a48_en.png"),
        },
        zh:{
          a41:require("../assets/a41.png"),
          a42:require("../assets/a42.png"),
          a43:require("../assets/a43.png"),
          a44:require("../assets/a44.png"),
          a45:require("../assets/a45.png"),
          a46:require("../assets/a46.png"),
          a47:require("../assets/a47.png"),
          a48:require("../assets/a48.png"),
        },
      },
      a4_imgs:{},
      imagesArr: {
        en: {
          a1: require("../assets/a1_en.png"),
          a11:require("../assets/a11_en.png"),
          a12:require("../assets/a12_en.png"),
          a13:require("../assets/a13_en.png"),
          a14:require("../assets/a14_en.png"),
        },
        zh: {
          a1: require("../assets/a1.png"),
          a11:require("../assets/a11.png"),
          a12:require("../assets/a12.png"),
          a13:require("../assets/a13.png"),
          a14:require("../assets/a14.png"),
        },
      },
      currentImages: {}, // will be updated in created() method

    };
  },
  components: {
    Tab,
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.changeLanguage(this.$i18n.locale);

  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  computed: {
    isMobile() {
      return this.windowWidth <= 600;
    },
  },

  methods: {
    mouseOver(image) {
      console.log(image)
      image.current = image.hover[this.lang];
    },
    mouseOut(image) {
      image.current = image.src[this.lang];
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    changeLanguage(lang) {
      this.lang = lang
      this.$i18n.locale = lang;
      if (lang === 'en') {
        this.a1 = require("../assets/a1_en.png")
        this.a1_phone = require("../assets/a1_phone_en.png")
        this.a2 = require("../assets/a2_en.png")
        this.a3 = require("../assets/a3_en.png")
        this.a4 = require("../assets/a4_en.png")
      } else {
        this.a1 = require("../assets/a1.png")
        this.a1_phone = require("../assets/a1_phone.png")
        this.a2 = require("../assets/a2.png")
        this.a3 = require("../assets/a3.png")
        this.a4 = require("../assets/a4.png")
        this.a4 = require("../assets/a4.png")
      }

      this.currentImages = [
        this.imagesArr[lang].a11,
        this.imagesArr[lang].a12,
        this.imagesArr[lang].a13,
        this.imagesArr[lang].a14,
      ];
      this.a4_imgs = [
        this.a4_arr[lang].a41,
        this.a4_arr[lang].a42,
        this.a4_arr[lang].a43,
        this.a4_arr[lang].a44,
        this.a4_arr[lang].a45,
        this.a4_arr[lang].a46,
        this.a4_arr[lang].a47,
        this.a4_arr[lang].a48,
      ]

      for (const image of this.images) {
        image.current = image.src[lang]
      }
      for (const item of this.img_arr) {
        item.current = item.img[lang]
      }
    },

  },
};
</script>

<style lang="less" scoped>
.view {
  max-width: 1600px;
  margin: 0 auto;

  > div {
    img {
      width: 100%;
    }
  }

  .title {
    height: 80px;
    color: #fff;
    font-size: 26px;
    line-height: 80px;
    background-color: #222222;
    position: relative;

    img {
      display: inline-block;
      margin-left: 80px;
      margin-top: 15px;
      width: 230px;
    }

    span {
      float: right;
      padding-right: 80px;
    }

    .language-switch {
      position: absolute;
      right: 10px;
      top: 10px;
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
      img {
        width: 30px;
        height: 30px;
      }
    }
  }
}

.content {
  text-align: center;
  box-sizing: border-box;
  // background-image: url("../assets/a1.png");
  height: 900px;
  background-size: 100%;
  h5 {
    font-size: 67.46px;
    color: #000;
  }

  p {
    letter-spacing: 8px;
    font-size: 26px;
    color: #969fa9;
    margin-bottom: 50px;
  }

  ul {
    display: flex;
    padding-top: 452px;
    li {
      flex-basis: 48%;
      margin-bottom: 20px;

      .image-wrapper {
        position: relative;
        overflow: hidden;

        img {
          display: block;
          width: 300px;
          transition: transform 0.3s ease-out;
          margin: 0 auto;

          &:hover {
            transform: translateY(-12px);
          }
        }
      }
    }
  }
}

.tab_box {
  background-color: #faf2fc;
  padding: 100px 100px;
  box-sizing: border-box;
  width: 100%;
  text-align: center;

  h4 {
    font-size: 36px;
    color: #000;
    margin-bottom: 30px;
  }
}

// 页脚
.bottom {
  background: rgba(22, 30, 48, 1);
  color: #fff;

  ul {
    padding-left: 150px;
    display: flex;
    padding-bottom: 44px;

    li {
      margin-right: 80px;
      margin-top: 40px;
    }

    .logo_box {
      padding: 50px 0;
    }

    .text_box {
      margin-right: 200px;

      div {
        padding-bottom: 5px;
      }

      .phone {
        font-size: 32.02px;
        padding: 10px 0;
      }
    }

    .img_box {
      width: 130px;
      text-align: center;

      img {
        width: 130px;
        height: 130px;
        padding: 10px 0;
      }
    }
  }

  p {
    height: 60px;
    line-height: 60px;
    text-align: center;
    border-top: 1px solid #969696;
    color: #bebebe;
  }
}

// 合作流程
.cooperation {
  background-image: url("../assets/a5.png");
  text-align: center;
  padding: 100px 0;
  h5 {
    color: #fff;
    font-size: 56px;
  }
  div {
    display: block;
    padding: 0 50px;
    height: 60px;
    width: 8%;
    line-height: 60px;
    color: #fff;
    background: #6c41ff;
    box-shadow: 0px 7px 27px 0px rgba(110, 79, 170, 0.51);
    border-radius: 10px;
    text-align: center;
    margin: 0 auto;
    margin-top: 50px;
    font-size: 24px;
  }
  ul {
    margin-top: 60px;
    li {
      display: inline-block;
      padding: 0 10px;
      img {
        width: 240px;
      }
      img:hover {
        transform: scale(1.1);
        transition: transform 0.3s;
      }
    }
  }
}

.block {
  position: relative;
  .text_box {
    margin-top: 100px;
    max-width: 415px;
    position: absolute;
    color: #fff;
    padding-top: 50px;
    box-sizing: border-box;
    z-index: 11;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 50px;
    h5 {
      text-align: center;
      font-size: 36px;
    }

    p {
      font-size: 20px;
      padding-right: 8px;
      text-align: center;
      font-size: 36px;
      margin-top: 20px;
    }

    > div {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      margin-right: 20px;
      margin: 0 auto;
      margin-top: 20px;

      span {
        a {
          display: inline-block;
          height: 42px;
          line-height: 42px;
          background-color: #9a40f4;
          border-radius: 21px;
          color: #fff;
          padding: 0 20px;
        }

        .gys {
          background-color: #fff;
          color: #000000;
        }
      }
    }
  }

  .item {
    position: relative;
    img {
      width: 100%;
      height: 620px;
    }

  }


}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.a4 {
  background-image: url("../assets/a4.png");
  height: 1117px;
  background-repeat: no-repeat;
  ul {
    width: 80%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 创建四列，每列的宽度都是相等的 */
    grid-template-rows: repeat(2, 1fr); /* 创建两行，每行的高度都是相等的 */
    padding: 0;
    list-style: none;
    padding-top: 250px;
  }

  li {
    width: 100%; /* 元素宽度为100%，以适应grid单元格 */
    box-sizing: border-box;
    transition: transform 0.3s ease;
  }
  li:hover {
    animation: shake 0.3s; /* 使用自定义的shake动画 */
    transform: scale(1.2);
  }

  @keyframes shake {
    0% {
      transform: translateX(0px) scale(1.2);
    }
    25% {
      transform: translateX(-5px) scale(1.1);
    }
    50% {
      transform: translateX(5px) scale(1.1);
    }
    75% {
      transform: translateX(-5px) scale(1.1);
    }
    100% {
      transform: translateX(0px) scale(1.1);
    }
  }
  li img {
    width: 100%;
    height: auto;
  }
}

.phone {
  .title {
    font-size: 18px;
    span {
      padding-right: 45px;
    }
  }
  .block {
    position: relative;
  }
  .block .item {
    height: 100%;
    img {
      height: 100%;
    }
  }
  .block .text_box {
    width: auto;

    padding: 0 15px;
    p {
      font-size: 20px;
    }
    a {
      font-size: 12px;
    }
  }
  .content {
    background-size: 100% 100%;
    height: auto;
    ul {
      padding-top: 190px;
      display: block;
      li {
        display: inline-block;
        width: 50%;
        .image-wrapper img {
          max-width: 160px;
          width: 100%;
        }
      }
    }
  }
  .a5 {
    background-color: #222222;
    color: #fff;
    text-align: center;
    padding-bottom: 48px;

    h5 {
      font-size: 30px;
      padding: 20px;
    }
    ul{
      padding: 0 14px;
      margin-bottom: 20px;
      li {
        display: inline-block;
        width: 50%;
        box-sizing: border-box;
        padding: 10px;
        img {
          width: 100%;
          border: 2px solid #8b8989;
          border-radius: 20%;          
        }
      }
    }
    .btn {
      border-radius: 12px 12px 12px 12px;
      background-color: #6C41FF;
      color: #fff;
      height: 80px;
      line-height: 80px;
      width: 80%;
      font-size: 30px;
      margin: 0 auto;
    }
  }
  .a6 {
    background-color: #1A0F42;    
    color: #fff;
    padding: 15px;
    font-size: 18px;
    padding-bottom: 50px;
    .logo {
      text-align: center;
      width: 80%;
      display: block;
      margin: 20px auto;
      
    }
    .code {
      text-align: center;
      width: 60%;
      display: block;
      margin: 20px auto;
    }
    .text {
      text-align: center;
      font-size: 20px;
    }
    .info {
      >div{
        padding-bottom: 5px;
      }
      .phone_number {
        font-size: 30px;
      }
    }

  }
}

</style>
