<template>
  <div class="tab">
    <div class="tab-header">
      <ul>
        <li
          v-for="(tab, index) in filteredTabs"
          :key="index"
          :class="{ active: activeTab === tab.name }"
          @click="activeTab = tab.name"
        >
          {{ tab.name }}
        </li>
      </ul>
    </div>
    <div class="tab-content">
      <div v-for="(category, index) in activeTabCategories" :key="index" class="category-item">
        {{ category }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tab",
  data() {
    return {
      tabs: [
        {
          name: "国家",
          categories: ['老挝','马来西亚','泰国','缅甸','柬埔寨','越南'],
        },
        {
          name: "男装",
          categories: [
            "欧美成衣",
            "中东成衣",
            "牛仔",
            "内衣",
            "家居服",
            "运动",
            "毛衣",
            "泳装",
            "外套",
            "孕妇装",
            "派对礼服",
            "婚礼礼服",
          ],
        },
        {
          name: "女装",
          categories: [
            "欧美成衣",
            "中东成衣",
            "牛仔",
            "家居服",
            "内衣",
            "运动",
            "泳衣",
            "外套",
            "毛衣",
            "礼服",
            "瑜伽服",
            "Cosplay制服装",
          ],
        },

      ],
      activeTab: "国家",
    };
  },
  computed: {
    filteredTabs() {
      return this.tabs.filter((tab) => tab.categories.length > 0);
    },
    activeTabCategories() {
      const activeTabObj = this.tabs.find((tab) => tab.name === this.activeTab);
      return activeTabObj ? activeTabObj.categories : [];
    },
  },
};
</script>

<style scoped lang="less">
.tab-header {
  text-align: center;
  margin-bottom: 30px;
  li {
    padding: 0 30px;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    background: #E5E5E5;
    border-radius: 20px;
    color: #333333;
    margin-right: 20px;
    transition: background-color 0.3s ease-in-out;

  }
  li.active {
    color: #fff;
    background: #6E4FAA;
  }
  li:hover {
    color: #fff;
    background-color: #6E4FAA;
  }  
}
.tab-content {
  display: flex;
  flex-wrap: wrap;
  div {
    flex-basis:8%;
    margin: 0 10px;
    display: inline-block;
    height: 50px;
    line-height: 50px;
    padding: 0 20px;
    box-shadow: 0px 7px 10px 0px rgba(110,79,170,0.36);
    background-color: #fff;
    margin-bottom: 15px;
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.3s ease-out;
  }
  .category-item {
    transition: transform 0.2s ease-in-out;
  }

  .category-item:hover {
    transform: translateY(-12px);
  }
}
</style>
