// i18n.js
export const messages = {
    en: {
      message: {
        ServiceHotline: 'Service Hotline',
        ContactEmail: 'Contact Email',
        bannerText: 'Find premium supply chain partners globally',
        openStore: 'Open a store for free',
        applySupplier: 'Apply to be a supplier',
        joinNow: 'Join now',
        contactNumber: 'Contact number:',
        Address: 'Address: Zhongjian Wisdom Valley, Yuelu Street, Yuelu District, Changsha City, Hunan Province',
        Administrative: 'Administrative office number: 0731-8550-8601',
        ZipCode: 'Zip code: 410000',
        WeChat: 'WeChat customer service',
        QR: 'Scan QR to apply to become a seller',
        Haodi: 'Changsha Haodi Technology Co., Ltd. All rights reserved.',
        ICP: 'Hunan ICP No. 20230169166-1',
      }
    },
    zh: {
      message: {
        ServiceHotline: '服务热线',
        ContactEmail: '联系邮箱',
        bannerText: '全球招募 优质供应链合伙伙伴',
        openStore: '免费开店',
        applySupplier: '申请成为供应商',
        joinNow: '立即合作',
        contactNumber: '联系电话',
        Address: '地址：湖南省长沙市岳麓区岳麓街道中建智慧产业园',
        Administrative: '行政办公电话：0731-8550-8601',
        ZipCode: '邮编：410000',
        WeChat: '微信客服',
        QR: '扫码申请成为商家',
        Haodi: '长沙好滴科技有限公司版权所有',
        ICP: '湘ICP备2023016916号-1',

      }
    }
  }
  